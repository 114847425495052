<template>
  <div class="input-wrapper">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/></svg> -->
    <input
      type="date"
      :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs">
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
    type: [String, Number],
    default: '',
    }
  }
}
</script>

<style scoped>

input {
  @apply 
    relative h-full w-full bg-transparent focus:outline-none
    text-[10px] sm:text-sm pl-2 cursor-pointer
}

.input-wrapper {
  @apply 
    flex px-1 sm:px-3 items-center
    relative h-full w-full
    rounded-lg bg-[#F5F8FA] hover:bg-[#eef3f7] transition-colors duration-300
}

svg {
  @apply w-4 h-4 fill-[#9A9EA4]
}

</style>